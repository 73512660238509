import React, { memo } from 'react';

import { PROJECT_IDS } from 'config/constants/projects/constants';
import {
  WidgetsColumnPropsType,
  renderWidgetsList,
} from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn';
import { WidgetsListBase } from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn/components/Base';

const widgetsConfig = [
  { projectId: PROJECT_IDS.Woman, index: 0 },
  { projectId: PROJECT_IDS.Woman, index: 1 },
  { projectId: PROJECT_IDS.Woman, index: 2 },
  { projectId: PROJECT_IDS.Woman, index: 3 },
  { projectId: PROJECT_IDS.Kino, index: 0 },
  { projectId: PROJECT_IDS.Kino, index: 1 },
  { projectId: PROJECT_IDS.Kino, index: 2 },
];

export const WeekendWidgetsHome = memo(({ puids }: WidgetsColumnPropsType) => (
  <WidgetsListBase puids={puids}>
    {renderWidgetsList(widgetsConfig)}
  </WidgetsListBase>
));
