import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectPageName } from 'common/redux/appController/selectors';
import { PAGE_TYPE } from 'config/constants/routerName';
import { WidgetsColumnPropsType } from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn';

import { WeekendWidgetsHome } from './components/WeekendWidgetsHome';
import { WeekendWidgetsTopic } from './components/WeekendWidgetsTopic';

/**
 * Список виджетов для второй колонки для вертикали Субботний
 * @param puids – объект пуидов для рекламы
 */
export const WeekendWidgets = memo(({ puids }: WidgetsColumnPropsType) => {
  const pageName = useSelector(selectPageName);

  switch (pageName) {
    case PAGE_TYPE.home:
      return <WeekendWidgetsHome puids={puids} />;
    case PAGE_TYPE.topic:
      return <WeekendWidgetsTopic puids={puids} />;

    default:
      return <WeekendWidgetsHome puids={puids} />;
  }
});
